import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`Today is our free class at 9:00 & 10:00am so bring a friend!  If you
haven’t done CrossFit before don’t worry, our classes will be beginner
friendly.`}{`*`}</strong></p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s and Sunday’s each weekend after
our CrossFit classes.  Email Daniel for more information.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up January 12th.  Get your
teams together and registered soon to reserve your spot!  December 17th
is the last day to register and get your Granite Games shirt on the day
of the competition. If you’d like to be on a team but don’t have 2 other
teammates please put your name on the white board in back and we’ll get
you on a team. `}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w"
      }}>{`https://competitioncorner.net/events/1549?fbclid=IwAR0ST57-McRs4RyVzEL5D6sr_LV403-KCqTNNEKweUXuq32ASGMdLOHFY4s#.W_biri3Mz1w`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      